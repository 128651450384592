import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/reducer';
import { LocalizeProvider } from "react-localize-redux";


ReactDOM.render(
    <LocalizeProvider>
        <Provider store={store}>
            <App store={store} />
        </Provider>
    </LocalizeProvider>,
    document.getElementById('root'));
