import { convertDateToUTC, formatDate } from './Utilities';

export const GTT_DATETIME_SERVICE_FORMAT = '#YYYY#-#MM#-#DD#T#hhhh#:#mm#:#ss#';
export const GTT_DATETIME_PRESENTATION_FORMAT = '#DD#/#MM#/#YYYY# #hhhh#:#mm#';

const GTT_OPERATORE = 'SMARTTICKET';
const GTT_CODICE_AZIENDA = 'GTTTORINO';

export function buildRecuperoSosteRequest(from, to) {
    let fromUTC = convertDateToUTC(from);
    let toUTC = convertDateToUTC(to);
    let timestamp = formatDate(new Date(), GTT_DATETIME_SERVICE_FORMAT);
    return {
        timestampInizio: formatDate(fromUTC, GTT_DATETIME_SERVICE_FORMAT),
        timestampFine: formatDate(toUTC, GTT_DATETIME_SERVICE_FORMAT),
        operatore: GTT_OPERATORE,
        timestampChiamata: timestamp,
        codice_azienda: GTT_CODICE_AZIENDA
    };
}

export function buildVerificaSostaRequest(timestampVerifica, plate, zone) {
    let timestampVerificaUTC = convertDateToUTC(timestampVerifica);
    let timestamp = formatDate(new Date(), GTT_DATETIME_SERVICE_FORMAT);
    return {
        timestampVerifica: formatDate(timestampVerificaUTC, GTT_DATETIME_SERVICE_FORMAT),
        targa: plate,
        zonaTariffaria: zone,
        operatore: GTT_OPERATORE,
        timestampChiamata: timestamp,
        codice_azienda: GTT_CODICE_AZIENDA
    };
}