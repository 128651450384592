export const pro = {
    cognito_uri: 'https://www-adminportal-smarticket.auth.eu-west-1.amazoncognito.com',
    client_id: '1mk4d8hnn5oa0g84leq9abj35i',
    redirect_uri: 'https://adminportal.smarticket.it/',
    scope: 'openid',
    logout_uri: 'https://adminportal.smarticket.it/logout',
    stfe_rest_uri: 'https://adminportal.smarticket.it/api',
    stfe_soap_wsdl_uri: 'https://adminportal.smarticket.it/api/GTT/ControlloSosteOperatore?wsdl',
    root_dir: ''
}
