import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class STPopup extends Component {

    render() {
        return (
            <Modal show={this.props.show}
                onHide={this.props.handleClosure}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body}
                    {this.props.button &&
                        <Button variant="primary" type="submit" disabled={this.props.isLoading} onClick={!this.props.isLoading ? this.props.buttonAction : null}>
                            {this.props.isLoading ? this.props.buttonTextLoading : this.props.buttonText}
                        </Button>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

export default STPopup;