import React, { Component, Fragment } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../redux/action';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';

class Logout extends Component {

    componentDidMount() {    
        import("../styles/index_whitelabel.css");
        this.props.logout();
        sessionStorage.clear();  
    }

    render() {       
        return (
            <Fragment className="container">
                <main className="st-main__logout">
                    <Header isLogout={true} />
                    <div className="container st-container__logout">
                    <h1><Translate id="title_session_ended" /></h1>
                    <p><Translate id="text_session_ended" /></p>
                    <Link className="mt-3 st-primary__button text-center" to="/"><Translate id="button_login" /></Link>
                    </div>
                </main>
            </Fragment>
        );
    }
}

Logout.propTypes = {
    logout: PropTypes.func
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(null, mapDispatchToProps)(withLocalize(Logout));