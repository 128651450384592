import { createStore } from 'redux';
import { SET_USER, SET_CLIENT, LOGIN, LOGOUT, SET_ENHANCED_ACTIONS, SET_ENHANCED_ACTION_TYPE, SET_LANGUAGES, SET_DEFAULT_LANGUAGE } from './action';

const initialState = {
    session: {
        user: null,
        client: null,
        token: null,
        ongoing: false,
        enhancedActions: false,
        enhancedActionType: '',
        languages: [],
        defaultLanguage: ''
    }
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    user: action.payload.user
                }
            });
        case SET_CLIENT:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    client: action.payload.client
                }
            });
        case SET_ENHANCED_ACTIONS:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    enhancedActions: action.payload.enhancedActions
                }
            });
        case SET_ENHANCED_ACTION_TYPE:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    enhancedActionType: action.payload.enhancedActionType
                }
            });
        case SET_LANGUAGES:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    languages: action.payload.languages
                }
            });
        case SET_DEFAULT_LANGUAGE:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    defaultLanguage: action.payload.defaultLanguage
                }
            });
        case LOGIN:
            return Object.assign({}, state, {
                session: {
                    ...state.session,
                    token: action.payload.token,
                    ongoing: true
                }
            });
        case LOGOUT:
            return Object.assign({}, state, {
                session: {
                    user: null,
                    token: null,
                    client: null,
                    enhancedActions: false,
                    enhancedActionType: '',
                    languages: [],
                    defaultLanguage: '',
                    ongoing: false
                }
            });
        default:
            return state;
    }
}


export const store = createStore(reducer, initialState);
