import React, {Component} from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Container, Row, Image } from 'react-bootstrap';
import iconSummary from '../images/icons/list.svg';
import iconSearch from '../images/icons/search.svg';
import iconReceipt from '../images/icons/file.svg';
import { RIEPILOGO_SOSTE, VERIFICA_SOSTA, RIEPILOGO_FATTURE } from '../App';
import withGracefulUnmount from './shared/STGracefulUnmount';
import Header from "./Header";
import PropTypes from "prop-types";
import { logout } from '../redux/action';
import { connect } from 'react-redux';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            errorDescription: '',
            errorTitle: ''
        }
    }

    render() {
        return (
            <div>
                <Header />

                <Container className="st-container">
                    <Row className="st-home__buttons mt-5">
                        <Button variant="primary" type="submit" className="st-primary__button mb-5" onClick={() => { this.props.history.push(RIEPILOGO_SOSTE); }}>
                            <Image src={iconSummary} width="30" height="30" className="mr-2" /><Translate id='button_riepilogo_soste' />
                        </Button>
                    </Row>
                    <Row className="st-home__buttons mb-5">
                        <Button variant="secondary" type="submit" className="st-secondary__button" onClick={() => { this.props.history.push(VERIFICA_SOSTA); }}>
                            <Image src={iconSearch} width="30" height="30" className="mr-2" /><Translate id='button_verifica_sosta' />
                        </Button>
                    </Row>
                    <Row className="st-home__buttons mb-5">
                        <Button variant="tertiary" type="submit" className="st-tertiary__button" onClick={() => { this.props.history.push(RIEPILOGO_FATTURE); }}>
                            <Image src={iconReceipt} width="30" height="30" className="mr-2" /><Translate id='button_riepilogo_fatture' />
                        </Button>
                    </Row>
                </Container>
            </div>
        )
    }
}

Home.propTypes = {
    token: PropTypes.string,
    logout: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        token: state.session.token,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withGracefulUnmount(withLocalize(Home)));
