export const SET_USER = 'SET_USER';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_ENHANCED_ACTIONS = 'SET_ENHANCED_ACTIONS';
export const SET_ENHANCED_ACTION_TYPE = 'SET_ENHANCED_ACTION_TYPE';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';


export function setUser(_user) {
    return {
        type: SET_USER,
        payload: { user: _user }
    };
}

export function setEnhancedActions(_enhancedActions) {
    return {
        type: SET_ENHANCED_ACTIONS,
        payload: { enhancedActions: _enhancedActions }
    };
}

export function setEnhancedActionType(_enhancedActionType) {
    return {
        type: SET_ENHANCED_ACTION_TYPE,
        payload: { enhancedActionType: _enhancedActionType }
    };
}

export function setLanguages(_languages) {
    return {
        type: SET_LANGUAGES,
        payload: { languages: _languages }
    };
}

export function setDefaultLanguage(_defaultLanguage) {
    return {
        type: SET_DEFAULT_LANGUAGE,
        payload: { defaultLanguage: _defaultLanguage }
    };
}

export function setClient(_client) {
    return {
        type: SET_CLIENT,
        payload: { client: _client }
    };
}

export function login(_token) {
    return {
        type: LOGIN,
        payload: { token: _token }
    };
}

export function logout() {
    return {
        type: LOGOUT,
        payload: {}
    };
}
