import { REST_DOWNLOAD_FATTURA } from '../App';

export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PATCH = "PATCH";
export const METHOD_DELETE = "DELETE";

export const HEADER_CONTENT_TYPE_JSON_VALUE = "application/json";

export const OK_CODE = 200;
export const CREATED_CODE = 201;
export const NO_CONTENT_CODE = 204;

export const UNAUTHORIZED_CODE = 401;

export const DEFAULT_HEADERS = (token) => {
    return (
        new Headers({
            "Content-Type": HEADER_CONTENT_TYPE_JSON_VALUE,
            "Authorization": "Bearer " + token
        })
    );
}

export function AsyncRequest(url, method, headers, body, successCode, successCallback, logoutCallback, errorCallback, finallyCallback) {
    this.url = url;
    this.method = method;
    this.headers = headers;
    this.body = body;
    this.successCode = successCode;
    this.successCallback = successCallback;
    this.logoutCallback = logoutCallback;
    this.errorCallback = errorCallback;
    this.finallyCallback = finallyCallback;
}

export function doAsync(asyncRequest) {
    var res;
    fetch(asyncRequest.url, {
        method: asyncRequest.method,
        headers: asyncRequest.headers,
        body: asyncRequest.body

    }).then(response => {
        res = response;
        if (asyncRequest.successCode !== res.status) {
            return response;
        } else if (asyncRequest.successCode === NO_CONTENT_CODE) {
            return response;
        } else if (asyncRequest.url.indexOf(REST_DOWNLOAD_FATTURA) !== -1) {
            return response.blob();
        }
        return response.json();

    }).then(object => {
        if (res.status === asyncRequest.successCode) {
            console.log("Success " + asyncRequest.method + " " + asyncRequest.url);
            console.log(object);
            if (typeof asyncRequest.successCallback === "function") {
                console.log("Calling success callback");
                asyncRequest.successCallback(object);
            }
        }
        else {
            console.log("Error code " + res.status + " in " + asyncRequest.method + " " + asyncRequest.url);
            if (res.status === UNAUTHORIZED_CODE) {
                if (typeof asyncRequest.logoutCallback === "function") {
                    console.log("Calling logout callback");
                    asyncRequest.logoutCallback();
                }
            }
            console.log(object);
            if (typeof asyncRequest.errorCallback === "function") {
                console.log("Calling error callback");
                asyncRequest.errorCallback(object);
            }
            if (typeof asyncRequest.finallyCallback === "function") {
                console.log("Calling finally callback");
                asyncRequest.finallyCallback(object);
            }
        }
    }).catch(error => {
        console.log(error);
        if (typeof asyncRequest.errorCallback === "function") {
            console.log("Calling error callback");
            asyncRequest.errorCallback(error);
        }
    }).then(object => {
        if (typeof asyncRequest.finallyCallback === "function") {
            console.log("Calling finally callback");
            asyncRequest.finallyCallback(object);
        }
    });
}
