import React, { Component } from 'react';
import STPopup from './shared/STPopup';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import { KO_STATUS, LOGOUT, REST_VERIFICA_SOSTA, stfeRestUri } from '../App';
import withGracefulUnmount from './shared/STGracefulUnmount';
import DateTimePicker from 'react-datetime-picker';
import iconBack from '../images/icons/arrow-left.svg';
import { GTT_DATETIME_PRESENTATION_FORMAT, buildVerificaSostaRequest } from '../utils/GttUtilities';
import { formatDate } from '../utils/Utilities';
import Header from "./Header";
import * as Cm from './ConnectionManager';
import PropTypes from 'prop-types';
import { logout } from '../redux/action';
import { connect } from 'react-redux';
import emptyList from '../images/st-empty-list-image.svg';
import { Redirect } from 'react-router-dom';

class VerificaSosta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchDone: false,
            loading: false,
            timestamp: null,
            plate: null,
            zone: null,
            sosta: null,
            showError: false,
            errorTitle: '',
            errorDescription: '',
            logoutError: null // when not null, render method will redirect to logout
        }

        this.handleErrorPopupClose = this.handleErrorPopupClose.bind(this);
        this.verificaSosta = this.verificaSosta.bind(this);
        this.displayElement = this.displayElement.bind(this);
    }

    handleErrorPopupClose() {
        this.setState({ showError: false, errorTitle: '', errorDescription: '' });
    }

    verificaSosta() {
        console.log("verificaSosta...");
        let bodyToSend = buildVerificaSostaRequest(this.state.timestamp, this.state.plate, this.state.zone);
        let bodyToSendJson = JSON.stringify(bodyToSend);
        console.log("request body");
        console.log(bodyToSendJson);
        this.setState({ loading: true })

        Cm.doAsync(new Cm.AsyncRequest(
            stfeRestUri + REST_VERIFICA_SOSTA,
            Cm.METHOD_POST,
            Cm.DEFAULT_HEADERS(this.props.token),
            bodyToSendJson,
            Cm.OK_CODE,
            (result) => {
                if (result.esito === KO_STATUS) {
                    let errorMessage = result.messaggio;
                    console.log("service error: " + errorMessage);
                    this.setState({ sosta: null, showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: errorMessage });
                } else {
                    console.log("EsitoVerificaSosta:");
                    console.log(result);
                    this.setState({ sosta: result.sosta.ultimaSosta });
                }
            },
            () => {
                console.log("logoutCallback");
                this.setState({ logoutError: true }, this.props.logout)
            },
            (error) => {
                console.log("errorCallback");
                this.setState({ sosta: null, showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: error });
            }, () => {
                this.setState({ searchDone: true, loading: false });
            }
        ));
    }

    displayElement(element) {

        const logoutError = this.state.logoutError;
        if (logoutError) {
            return <Redirect to={"/" + LOGOUT}/>;
        }

        let toReturn = [];
        // HEADER
        toReturn.push(
            <Table responsive striped hover className="mt-5">
                <thead>
                <tr className="st-table__tr-container">
                    <th className="st-width-20-th-message"><Translate id='table_verifica_sosta_header_id' /></th>
                    <th className="st-width-20-th-message"><Translate id='table_verifica_sosta_header_start' /></th>
                    <th className="st-width-20-th-message"><Translate id='table_verifica_sosta_header_end' /></th>
                    <th className="st-width-10-th-message"><Translate id='table_verifica_sosta_header_plate' /></th>
                    <th className="st-width-10-th-message"><Translate id='table_verifica_sosta_header_type' /></th>
                    <th className="st-width-10-th-message"><Translate id='table_verifica_sosta_header_zone' /></th>
                    <th className="st-width-10-th-message"><Translate id='table_verifica_sosta_header_amount' /></th>
                </tr>
                </thead>
            </Table>
        );
        // ROW
        toReturn.push(
            <Table key="element" responsive hover className="mt-0 mb-0">
                <tbody>
                <tr key="element">
                    <td className="st-width-20-td-message">{element.idSosta}</td>
                    <td className="st-width-20-td-message">{element.inizioSosta ? formatDate(new Date(element.inizioSosta), GTT_DATETIME_PRESENTATION_FORMAT) : ''}</td>
                    <td className="st-width-20-td-message">{element.fineSosta ? formatDate(new Date(element.fineSosta), GTT_DATETIME_PRESENTATION_FORMAT) : ''}</td>
                    <td className="st-width-10-td-message"><strong>{element.targa}</strong></td>
                    <td className="st-width-10-td-message">{element.tipoSosta}</td>
                    <td className="st-width-10-td-message"><span className="st-table-id__zone">{element.zonaTariffaria}</span></td>
                    <td className="st-width-10-td-message">{element.importo ? element.importo.toFixed(2) : ''}</td>
                </tr>
                </tbody>
            </Table>
        );
        return toReturn;
    }

    render() {
        let searchEnabled = this.state.timestamp && this.state.plate && !this.state.loading;

        return (
            <div>
                <Header />

                <Container className="mt-5">
                    <Row>
                        <h4 className="st-back" onClick={() => this.props.history.push("/")}><Image src={iconBack} width="30" height="30" className="mr-2" /><Translate id='label_back' /></h4>
                    </Row>
                </Container>


                <Container  className="st-container mt-2">
                    <Row className="st-input__group-date">
                        <Col xs={12} xl={3}>
                            <h5><Translate id="label_verifica_sosta_verify_timestamp" /></h5>
                            <DateTimePicker
                                onChange={(value) => { let timestamp = new Date(value); this.setState({ timestamp: timestamp }); }}
                                value={this.state.timestamp}
                                className="st-riepilogo__date-input"
                                locale='it-it'
                            />
                        </Col>
                        <Col xs={12} xl={3}>
                            <h5><Translate id="label_verifica_sosta_verify_plate" /></h5>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control value={this.state.plate} className="st-riepilogo__date-input" placeholder={this.props.translate("label_verifica_sosta_verify_plate")} onChange={(event) => { this.setState({ plate: event.target.value }); }} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} xl={3}>
                            <h5><Translate id="label_verifica_sosta_verify_zone" /></h5>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control value={this.state.zone} className="st-riepilogo__date-input" placeholder={this.props.translate("label_verifica_sosta_verify_zone")} onChange={(event) => { this.setState({ zone: event.target.value }); }} />
                            </Form.Group>
                        </Col>

                        <Col xs={12} xl={3}>
                            <Button disabled={!searchEnabled} variant="primary" type="submit" className="st-primary__button st-btn__date__input" onClick={() => this.verificaSosta()}>
                                <Translate id='label_verifica_sosta_verify_button' />
                            </Button>
                        </Col>

                    </Row>

                    {/* LOADING */}
                    {this.state.loading &&
                    <div id="loader_overlay" className="loader_overlay"><Translate id="loading" /></div>
                    }

                    {/* NO RESULTS */}
                    {this.state.searchDone && !this.state.loading && !this.state.sosta &&
                    <center><Image src={emptyList} className="mt-5 mb-4" fluid /><br /><span className="st-text-empty-list"><Translate id="label_no_results" /></span></center>
                    }

                    {/* RESULTS */}
                    {this.state.searchDone && !this.state.loading && this.state.sosta &&
                    this.displayElement(this.state.sosta)
                    }
                </Container>

                <STPopup show={this.state.showError} title={this.state.errorTitle} body={this.state.errorDescription} handleClosure={this.handleErrorPopupClose} />
            </div>
        )
    }
}

VerificaSosta.propTypes = {
    token: PropTypes.string,
    logout: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        token: state.session.token,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withGracefulUnmount(withLocalize(VerificaSosta)));
