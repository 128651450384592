import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { login } from './redux/action';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "./translations.json";
import './styles/bootstrap/bootstrap.min.css';
import './styles/utilities.css';
import { local } from './conf/local.js';
import { dev } from './conf/dev.js';
import { pre } from './conf/pre.js';
import { pro } from './conf/pro.js';
import Home from "./component/Home";
import RiepilogoSoste from "./component/RiepilogoSoste";
import VerificaSosta from "./component/VerificaSosta";
import RiepilogoFatture from "./component/RiepilogoFatture";
import Logout from "./component/Logout";

export const RIEPILOGO_SOSTE = 'riepilogo-soste';
export const VERIFICA_SOSTA = 'verifica-sosta';
export const RIEPILOGO_FATTURE = 'riepilogo-fatture';
export const LOGOUT = 'logout';

export const REST_USER = "/token/v1/validateToken";
export const REST_RIEPILOGO_SOSTE = "/gtt/v1/RiepilogoSoste";
export const REST_VERIFICA_SOSTA = "/gtt/v1/VerificaSosta";
export const REST_RIEPILOGO_FATTURE = "/gtt/v1/RiepilogoFatture";
export const REST_DOWNLOAD_FATTURA = "/gtt/v1/DownloadFattura";

export function getCognitoLoginUrl(state) {
  return (
      cognitoUri + "/login?response_type=token&client_id=" + clientId + "&redirect_uri=" + redirectUri + "&scope=" + scope + "&state=" + state
  );
}

export function getCognitoLogoutUrl() {
  return (
      cognitoUri + "/logout?client_id=" + clientId + "&logout_uri=" + logoutUri
  );
}

const EXP_DATE = "Expiration Date";
const TOKEN = "Token";
const STATE = "State";
const TOKEN_PARAM = "access_token";
const EXP_PARAM = "expires_in";
const STATE_PARAM = "state";

export const DEV_URL = ['devweb2','util.opentech.com'];
export const PRE_URL = ['pre.adminportal.smarticket.it'];
export const PROD_URL = ['adminportal.smarticket.it'];

export const KO_STATUS = 'KO';

export var cognitoUri;
export var clientId;
export var redirectUri;
export var scope;
export var logoutUri;
export var stfeRestUri;
export var stfeSoapWsdlUri;
export var rootDir;

function verifySession() {
  let expDate = JSON.parse(sessionStorage.getItem(EXP_DATE));
  let token = JSON.parse(sessionStorage.getItem(TOKEN));
  let validSession = token && expDate && expDate > new Date().getTime();
  return validSession;
}


function generateState(num) {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < num; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export var logger = function () {
  var oldConsoleLog = null;
  var pub = {};

  pub.enableLogger = function enableLogger() {
    if (oldConsoleLog == null)
      return;

    window['console']['log'] = oldConsoleLog;
  };

  pub.disableLogger = function disableLogger() {
    oldConsoleLog = console.log;
    window['console']['log'] = function () { };
  };

  return pub;
}();

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetchedEnvProps: false
    };

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Italian", code: "it" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: "it"
      }
    });
  }

  componentWillMount() {
    if (DEV_URL.some(url => window.location.host.indexOf(url) === 0)) {
      cognitoUri = dev.cognito_uri;
      clientId = dev.client_id;
      redirectUri = dev.redirect_uri;
      scope = dev.scope;
      logoutUri = dev.logout_uri;
      stfeRestUri = dev.stfe_rest_uri;
      stfeSoapWsdlUri = dev.stfe_soap_wsdl_uri;
      rootDir = dev.root_dir;
      console.log("is Dev");
    }
    else if (PRE_URL.some(url => window.location.host.indexOf(url) === 0)) {
      cognitoUri = pre.cognito_uri;
      clientId = pre.client_id;
      redirectUri = pre.redirect_uri;
      scope = pre.scope;
      logoutUri = pre.logout_uri;
      stfeRestUri = pre.stfe_rest_uri;
      stfeSoapWsdlUri = pre.stfe_soap_wsdl_uri;
      rootDir = pre.root_dir;
      console.log("is Pre");
    }
    else if (PROD_URL.some(url => window.location.host.indexOf(url) === 0)) {
      cognitoUri = pro.cognito_uri;
      clientId = pro.client_id;
      redirectUri = pro.redirect_uri;
      scope = pro.scope;
      logoutUri = pro.logout_uri;
      stfeRestUri = pro.stfe_rest_uri;
      stfeSoapWsdlUri = pro.stfe_soap_wsdl_uri;
      rootDir = pro.root_dir;
      logger.disableLogger();
    }
    else {
      cognitoUri = local.cognito_uri;
      clientId = local.client_id;
      redirectUri = local.redirect_uri;
      scope = local.scope;
      logoutUri = local.logout_uri;
      stfeRestUri = local.stfe_rest_uri;
      stfeSoapWsdlUri = local.stfe_soap_wsdl_uri;
      rootDir = local.root_dir;
      console.log("is Local");
    }
  }

  render() {
    return (
        <Router basename={rootDir}>
          <Switch>
            <PrivateRoute exact path="/" store={this.props.store} component={Home} />
            <PrivateRoute exact path={"/" + RIEPILOGO_SOSTE} store={this.props.store} component={RiepilogoSoste} />
            <PrivateRoute exact path={"/" + VERIFICA_SOSTA} store={this.props.store} component={VerificaSosta} />
            <PrivateRoute exact path={"/" + RIEPILOGO_FATTURE} store={this.props.store} component={RiepilogoFatture} />
            <Route path={"/" + LOGOUT} component={Logout} />
            <PrivateRoute store={this.props.store} render={() => <Redirect to='/' />} />
          </Switch>
        </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, store, ...rest }) => {

  let validSession = false;
  let sessionHasErrors = false;

  let currentUrl = window.location + "";
  if (currentUrl.indexOf('#') !== -1) {
    sessionHasErrors = true; // assumo che abbia errori, se lo state coincide metto a false
    validSession = true;
    let authString = currentUrl.substring(currentUrl.indexOf('#') + 1);
    let params = authString.split("&");
    params.map(elem => {
      let splittedParam = elem.split("=");

      if (TOKEN_PARAM === splittedParam[0]) {
        sessionStorage.setItem(TOKEN, JSON.stringify(splittedParam[1]));
        //console.log("SessionStorage <-- token: " + splittedParam[1]);
      }
      else if (EXP_PARAM === splittedParam[0]) {
        let expDate = new Date().getTime() + splittedParam[1] * 1000;
        sessionStorage.setItem(EXP_DATE, JSON.stringify(expDate));
        //console.log("SessionStorage <-- expiration date: " + expDate);
      }
      else if (STATE_PARAM === splittedParam[0]) {
        let state = JSON.parse(sessionStorage.getItem(STATE));
        if (splittedParam[1] !== state) {
          //console.log("New session is not correct: " + splittedParam[1] + " !== " + state);
          validSession = false;
        } else {
          sessionHasErrors = false;
        }
      }
      return elem;
    });

  } else {
    validSession = verifySession();
  }

  if (sessionHasErrors) {
    //console.log("state param invalid -> redirect to Logout");
    return (
        <Route {...rest} render={props => window.location.href = '/logout'} />
    );
  }
  const state = generateState(6);
  if (!validSession) {
    sessionStorage.setItem(STATE, JSON.stringify(state));
    //console.log("Redirect to login, state: " + state);
  } else {
    store.dispatch(login(JSON.parse(sessionStorage.getItem(TOKEN))));
  }
  return (
      <Route {...rest} render={props => validSession ? <Component {...props} /> : window.location = getCognitoLoginUrl(state)} />
  );
};

export default withLocalize(App);
