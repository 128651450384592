import React, { Component } from 'react';
import STPopup from './shared/STPopup';
import { Translate, withLocalize } from 'react-localize-redux';
import { Container, Image, Row } from 'react-bootstrap';
import {KO_STATUS, LOGOUT, REST_DOWNLOAD_FATTURA, REST_RIEPILOGO_FATTURE, stfeRestUri} from '../App';
import iconBack from '../images/icons/arrow-left.svg';
import withGracefulUnmount from './shared/STGracefulUnmount';
import Header from './Header';
import * as Cm from './ConnectionManager';
import PropTypes from 'prop-types';
import { logout } from '../redux/action';
import { connect } from 'react-redux';
import emptyList from '../images/st-empty-list-image.svg';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { formatDate, humanFileSize, StringContains } from '../utils/Utilities';
import { GTT_DATETIME_PRESENTATION_FORMAT } from '../utils/GttUtilities';
import fileDownload from 'js-file-download';

const columns = [{
    dataField: 'chiaveFile',
    text: 'File',
    headerAlign: 'center',
    headerStyle: { backgroundColor: 'var(--primaryColor)', color: 'var(--brightColor)', verticalAlign: 'middle' },
    sort: true,
    headerSortingStyle: { backgroundColor: 'var(--accentColor)' },
    filter: textFilter(),
}, {
    dataField: 'dimensione',
    text: 'Dimensione',
    headerAlign: 'center',
    headerStyle: { backgroundColor: 'var(--primaryColor)', color: 'var(--brightColor)', verticalAlign: 'middle' },
    sort: true,
    headerSortingStyle: { backgroundColor: 'var(--accentColor)' },
}, {
    dataField: 'ultimaModifica',
    text: 'Ultima Modifica',
    headerAlign: 'center',
    headerStyle: { backgroundColor: 'var(--primaryColor)', color: 'var(--brightColor)', verticalAlign: 'middle' },
    sort: true,
    headerSortingStyle: { backgroundColor: 'var(--accentColor)' },
}];

class RiepilogoFatture extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstCallDone: false,
            loading: false,
            fatture: [],
            showError: false,
            errorTitle: '',
            errorDescription: '',
            logoutError: null // when not null, render method will redirect to logout
        }

        this.handleErrorPopupClose = this.handleErrorPopupClose.bind(this);
        this.getRiepilogoFatture = this.getRiepilogoFatture.bind(this);
        this.downloadFattura = this.downloadFattura.bind(this);
        this.displayElements = this.displayElements.bind(this);
    }

    componentDidMount() {
        this.getRiepilogoFatture();
    }

    handleErrorPopupClose() {
        this.setState({ showError: false, errorTitle: '', errorDescription: '' });
    }

    getRiepilogoFatture() {
        console.log("getRiepilogoFatture...");
        this.setState({ loading: true })

        Cm.doAsync(new Cm.AsyncRequest(
            stfeRestUri + REST_RIEPILOGO_FATTURE,
            Cm.METHOD_GET,
            Cm.DEFAULT_HEADERS(this.props.token),
            null,
            Cm.OK_CODE,
            (result) => {
                if (result.esito === KO_STATUS) {
                    let errorMessage = result.messaggio;
                    console.log("service error: " + errorMessage);
                    this.setState({ soste: [], showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: errorMessage });
                } else {
                    console.log("EsitoRiepilogoFatture:");
                    console.log(result);
                    this.setState({ fatture: result.fattura });
                }
            },
            () => {
                console.log("logoutCallback");
                this.setState({ logoutError: true }, this.props.logout)
            },
            (error) => {
                console.log("errorCallback");
                this.setState({ soste: [], showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: error });
            }, () => {
                this.setState({ firstCallDone: true, loading: false });
            }
        ));
    }

    downloadFattura(element) {
        console.log("downloadFattura... element");
        console.log(element);
        this.setState({ loading: true })

        Cm.doAsync(new Cm.AsyncRequest(
            stfeRestUri + REST_DOWNLOAD_FATTURA + "?chiaveFile=" + encodeURIComponent(element.chiaveFile) + "&_=" + new Date().getTime(),
            Cm.METHOD_GET,
            Cm.DEFAULT_HEADERS(this.props.token),
            null,
            Cm.OK_CODE,
            (result) => {
                if (result instanceof Blob) {
                    let key = element.chiaveFile;
                    let filename = StringContains(key, '/') ? key.split('/').pop() : key;
                    fileDownload(result, filename);
                } else {
                    console.log('downloadFattura error!');
                    console.log(result);
                    this.setState({ showError: true, errorTitle: result.errorTitle, errorDescription: result.errorDescription });
                }
            },
            () => {
                console.log("logoutCallback");
                this.setState({ logoutError: true }, this.props.logout)
            },
            (error) => {
                console.log("errorCallback");
                this.setState({ showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: error });
            }, () => {
                this.setState({ loading: false });
            }
        ));
    }

    displayElements(elements) {
        let data = elements
            .filter(elem => elem.dimensione > 0)
            .map(elem => {
                return {
                    chiaveFile: elem.chiaveFile,
                    dimensione: humanFileSize(elem.dimensione, true, 1),
                    ultimaModifica: formatDate(new Date(elem.ultimaModifica), GTT_DATETIME_PRESENTATION_FORMAT),
                }
            });
        let toReturn = [];
        toReturn.push(
            <BootstrapTable
                bootstrap4
                keyField='chiaveFile'
                data={ data }
                columns={ columns }
                rowEvents={{
                    onClick: (e, row, rowIndex) => {
                        this.downloadFattura(data[rowIndex]);
                    }
                }}
                filter={ filterFactory() }
                pagination={ paginationFactory() } />
        );
        return toReturn;
    }

    render() {

        const logoutError = this.state.logoutError;
        if (logoutError) {
            return <Redirect to={"/" + LOGOUT}/>;
        }

        let noResultsCondition = this.state.firstCallDone && !this.state.loading && this.state.fatture.length === 0;
        let resultsCondition = this.state.firstCallDone && !this.state.loading && this.state.fatture.length > 0;

        return (
            <div>
                <Header />
                <Container className="mt-5">
                    <Row>
                        <h4 className="st-back" onClick={() => this.props.history.push("/")}><Image src={iconBack} width="30" height="30" className="mr-2" /><Translate id='label_back' /></h4>
                    </Row>
                </Container>

                <Container className="st-container mt-2">

                    {/* LOADING */}
                    {this.state.loading &&
                    <div id="loader_overlay" className="loader_overlay"><Translate id="loading" /></div>
                    }

                    {/* NO RESULTS */}
                    {noResultsCondition &&
                    <center><Image src={emptyList} className="mt-5 mb-4" fluid /><br /><span className="st-text-empty-list"><Translate id="label_no_results" /></span></center>
                    }

                    {/* RESULTS */}
                    {resultsCondition &&
                        this.displayElements(this.state.fatture)
                    }
                </Container>

                <STPopup show={this.state.showError} title={this.state.errorTitle} body={this.state.errorDescription} handleClosure={this.handleErrorPopupClose} />
            </div>
        )
    }
}

RiepilogoFatture.propTypes = {
    token: PropTypes.string,
    logout: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        token: state.session.token,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withGracefulUnmount(withLocalize(RiepilogoFatture)));
