import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import logo from '../images/st-logo-smarticket.svg';
import iconUser from '../images/icons/user.svg';
import { stfeRestUri, REST_USER, LOGOUT, getCognitoLogoutUrl } from '../App';
import { connect } from 'react-redux';
import { setUser, setClient, logout, setEnhancedActions, setEnhancedActionType, setLanguages, setDefaultLanguage } from '../redux/action';
import * as Cm from './ConnectionManager';
import { Translate, withLocalize } from 'react-localize-redux';
import { Redirect } from 'react-router-dom';

export const CLIENT = "Client";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            logoutError: null // when not null, render method will redirect to logout
        }

        this.client = JSON.parse(sessionStorage.getItem(CLIENT));
    }

    componentDidMount() {
        if (this.props.ongoing && (this.props.user == null || this.props.user === "") && !this.props.isLogout) {
            this.setState({ isLoading: true });

            Cm.doAsync(new Cm.AsyncRequest(
                stfeRestUri + REST_USER,
                Cm.METHOD_GET,
                Cm.DEFAULT_HEADERS(this.props.token),
                null,
                Cm.OK_CODE,
                (user) => {
                    this.props.setUser(user.username);
                    this.props.setClient(user.client);
                    this.props.setEnhancedActions(user.enhancedActions);
                    this.props.setEnhancedActionType(user.enhancedActionType);
                    this.props.setLanguages(user.languages);
                    this.props.setDefaultLanguage(user.defaultLanguage);
                    sessionStorage.setItem(CLIENT, JSON.stringify(user.client));
                },
                () => {
                    console.log("logoutCallback");
                    this.setState({ logoutError: true }, this.props.logout)
                },
                () => {
                    console.log("errorCallback");
                    this.setState({ logoutError: true }, this.props.logout)
                },
                () => {
                    this.setState({ isLoading: false });
                }
            ));
        }
    }

    render() {
        import("../styles/index_whitelabel.css");


        const logoutError = this.state.logoutError;
        const user = this.props.user;
        const logout = getCognitoLogoutUrl();

        if (logoutError) {
            return <Redirect to={"/" + LOGOUT}/>;
        }

        return (
            <Fragment>
                <div className="st-loader">
                    {this.state.isLoading &&
                    <div id="loader_overlay" className="loader_overlay"><Translate id="loading" /></div>
                    }
                </div>

                <header className="container-fluid st-header">
                    <div className="container">
                        <nav className="st-header__nav">
                            <a href="/"><img src={logo} className="st-header__logo" alt="Smarticket.it logo" /></a>
                            {this.props.ongoing &&
                            <div className="st-header__contents">
                                <p className="st-header__contents__profile"><img src={iconUser} alt="Smarticket.it icona utente" className="mr-2" />{user}</p>
                                <span className="st-vertical__separator"></span>
                                <a href={logout}><button className="st-primary__btn --bright">Logout</button></a>
                            </div>
                            }
                        </nav>
                    </div>
                </header>
            </Fragment>
        );
    }
}

Header.propTypes = {
    user: PropTypes.string,
    client: PropTypes.string,
    token: PropTypes.string,
    ongoing: PropTypes.bool,
    setUser: PropTypes.func,
    setClient: PropTypes.func,
    setEnhancedActions: PropTypes.func,
    setEnhancedActionType: PropTypes.func,
    setLanguages: PropTypes.func,
    setDefaultLanguage: PropTypes.func,
    logout: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        user: state.session.user,
        client: state.session.client,
        token: state.session.token,
        ongoing: state.session.ongoing
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setClient: (client) => dispatch(setClient(client)),
        setEnhancedActions: (enhancedActions) => dispatch(setEnhancedActions(enhancedActions)),
        setEnhancedActionType: (enhancedActionType) => dispatch(setEnhancedActionType(enhancedActionType)),
        setLanguages: (languages) => dispatch(setLanguages(languages)),
        setDefaultLanguage: (language) => dispatch(setDefaultLanguage(language)),
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Header));
