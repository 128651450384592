import React, { Component } from 'react';
import STPopup from './shared/STPopup';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { KO_STATUS, LOGOUT, REST_RIEPILOGO_SOSTE, stfeRestUri } from '../App';
import iconBack from '../images/icons/arrow-left.svg';
import withGracefulUnmount from './shared/STGracefulUnmount';
import DateTimePicker from 'react-datetime-picker';
import { GTT_DATETIME_PRESENTATION_FORMAT, buildRecuperoSosteRequest } from '../utils/GttUtilities';
import { formatDate } from '../utils/Utilities';
import Header from './Header';
import * as Cm from './ConnectionManager';
import PropTypes from 'prop-types';
import { logout } from '../redux/action';
import { connect } from 'react-redux';
import emptyList from '../images/st-empty-list-image.svg';
import { Redirect } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv';

const csv_options = {
    filename: 'export',
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

class RiepilogoSoste extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchDone: false,
            loading: false,
            from: null,
            to: null,
            soste: [],
            showError: false,
            errorTitle: '',
            errorDescription: '',
            logoutError: null // when not null, render method will redirect to logout
        }

        this.csvExporter = new ExportToCsv(csv_options);

        this.handleErrorPopupClose = this.handleErrorPopupClose.bind(this);
        this.getRiepilogoSoste = this.getRiepilogoSoste.bind(this);
        this.displayElements = this.displayElements.bind(this);
        this.exportElements = this.exportElements.bind(this);
    }

    handleErrorPopupClose() {
        this.setState({ showError: false, errorTitle: '', errorDescription: '' });
    }

    getRiepilogoSoste() {
        console.log("getRiepilogoSoste...");
        let bodyToSend = buildRecuperoSosteRequest(this.state.from, this.state.to);
        let bodyToSendJson = JSON.stringify(bodyToSend);
        console.log("request body");
        console.log(bodyToSendJson);
        this.setState({ loading: true })

        Cm.doAsync(new Cm.AsyncRequest(
            stfeRestUri + REST_RIEPILOGO_SOSTE,
            Cm.METHOD_POST,
            Cm.DEFAULT_HEADERS(this.props.token),
            bodyToSendJson,
            Cm.OK_CODE,
            (result) => {
                if (result.esito === KO_STATUS) {
                    let errorMessage = result.messaggio;
                    console.log("service error: " + errorMessage);
                    this.setState({ soste: [], showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: errorMessage });
                } else {
                    console.log("EsitoRiepilogoSoste:");
                    console.log(result);
                    this.setState({ soste: result.sosta });
                }
            },
            () => {
                console.log("logoutCallback");
                this.setState({ logoutError: true }, this.props.logout)
            },
            (error) => {
                console.log("errorCallback");
                this.setState({ soste: [], showError: true, errorTitle: this.props.translate('title_service_error'), errorDescription: error });
            }, () => {
                this.setState({ searchDone: true, loading: false });
            }
        ));
    }

    displayElements(elements) {
        let toReturn = [];
        // HEADER
        toReturn.push(
                <Table responsive striped hover className="mt-5">
                    <thead>
                    <tr className="st-table__tr-container">
                        <th className="st-width-20-th-message"><Translate id='table_riepilogo_soste_header_id' /></th>
                        <th className="st-width-15-th-message"><Translate id='table_riepilogo_soste_header_start' /></th>
                        <th className="st-width-15-th-message"><Translate id='table_riepilogo_soste_header_end' /></th>
                        <th className="st-width-10-th-message"><Translate id='table_riepilogo_soste_header_plate' /></th>
                        <th className="st-width-10-th-message"><Translate id='table_riepilogo_soste_header_type' /></th>
                        <th className="st-width-10-th-message"><Translate id='table_riepilogo_soste_header_zone' /></th>
                        <th className="st-width-5-th-message"><Translate id='table_riepilogo_soste_header_amount' /></th>
                        <th className="st-width-15-th-message"><Translate id='table_riepilogo_soste_header_insertion' /></th>
                    </tr>
                    </thead>
                </Table>
        );
        // ROWS
        for (let count = 0; count < elements.length; count++) {
            let elem = elements[count];
            toReturn.push(
                <Table key={count} responsive hover className="mt-0 mb-0">
                    <tbody>
                    <tr key={count}>
                        <td className="st-width-20-td-message"><span className="st-table-id__sosta">{elem.idSosta}</span></td>
                        <td className="st-width-15-td-message">{elem.inizioSosta ? formatDate(new Date(elem.inizioSosta), GTT_DATETIME_PRESENTATION_FORMAT) : ''}</td>
                        <td className="st-width-15-td-message">{elem.fineSosta ? formatDate(new Date(elem.fineSosta), GTT_DATETIME_PRESENTATION_FORMAT) : ''}</td>
                        <td className="st-width-10-td-message"><strong>{elem.targa}</strong></td>
                        <td className="st-width-10-td-message">{elem.tipoSosta}</td>
                        <td className="st-width-10-td-message"><span className="st-table-id__zone">{elem.zonaTariffaria}</span></td>
                        <td className="st-width-5-td-message">{elem.importo ? elem.importo.toFixed(2) : ''}</td>
                        <td className="st-width-15-td-message">{elem.inserimentoRecord ? formatDate(new Date(elem.inserimentoRecord), GTT_DATETIME_PRESENTATION_FORMAT) : ''}</td>
                    </tr>
                    </tbody>
                </Table>
            );
        }
        return toReturn;
    }

    exportElements(elements) {
        /* Questo mapping è necessario per:
            - ridefinire l'ordinamento dei campi che saranno le colonne del csv
            - eliminare il campo 'cliente' che altrimenti verrebbe esportato sempre come 'null'
            - formattare i campi dell'export
         */
        elements = elements.map(elem => {
            return {
                idSosta: elem.idSosta,
                inizioSosta: elem.inizioSosta ? formatDate(new Date(elem.inizioSosta), GTT_DATETIME_PRESENTATION_FORMAT) : '',
                fineSosta: elem.fineSosta ? formatDate(new Date(elem.fineSosta), GTT_DATETIME_PRESENTATION_FORMAT) : '',
                targa: elem.targa,
                tipoSosta: elem.tipoSosta,
                zonaTariffaria: elem.zonaTariffaria,
                importo: elem.importo ? elem.importo.toFixed(2) : '',
                inserimentoRecord: elem.inserimentoRecord ? formatDate(new Date(elem.inserimentoRecord), GTT_DATETIME_PRESENTATION_FORMAT) : ''
            }
        });

        this.csvExporter.generateCsv(elements);
    }

    render() {
        let searchEnabled = this.state.from && this.state.to && !this.state.loading;

        let fromMinDate = null;
        let fromMaxDate = this.state.to ? this.state.to : new Date();
        let toMinDate = this.state.from ? this.state.from : null;
        let toMaxDate = new Date();

        const logoutError = this.state.logoutError;
        if (logoutError) {
            return <Redirect to={"/" + LOGOUT}/>;
        }

        let noResultsCondition = this.state.searchDone && !this.state.loading && this.state.soste.length === 0;
        let resultsCondition = this.state.searchDone && !this.state.loading && this.state.soste.length > 0;

        return (
            <div>
                <Header />
                <Container className="mt-5">
                    <Row>
                        <h4 className="st-back" onClick={() => this.props.history.push("/")}><Image src={iconBack} width="30" height="30" className="mr-2" /><Translate id='label_back' /></h4>
                    </Row>
                </Container>

                <Container className="st-container mt-2">

                    <Row className="st-input__group-date">
                        <Col xs={12} xl={3}>
                            <h5><Translate id="label_riepilogo_soste_search_from_date" /></h5>
                            <DateTimePicker
                                onChange={(value) => { let from = new Date(value); this.setState({ from: from }); }}
                                value={this.state.from}
                                minDate={fromMinDate}
                                maxDate={fromMaxDate}
                                className="st-riepilogo__date-input"
                                locale='it-it'
                            />
                        </Col>
                        <Col xs={12} xl={3}>
                            <h5><Translate id="label_riepilogo_soste_search_to_date" /></h5>
                            <DateTimePicker
                                onChange={(value) => { let to = new Date(value); this.setState({ to: to }); }}
                                value={this.state.to}
                                minDate={toMinDate}
                                maxDate={toMaxDate}
                                className="st-riepilogo__date-input"
                                locale='it-it'
                            />
                        </Col>
                        <Col xs={12} xl={3}>
                            <Button disabled={!searchEnabled} variant="primary" type="submit" className="st-primary__button st-btn__date__input" onClick={() => this.getRiepilogoSoste()}>
                                <Translate id='label_riepilogo_soste_search_button' />
                            </Button>
                        </Col>
                        <Col xs={12} xl={3}>
                            <Button disabled={!resultsCondition} variant="secondary" type="submit" className="st-secondary__button st-btn__date__input" onClick={() => this.exportElements(this.state.soste)}>
                                <Translate id='label_riepilogo_soste_csv_button' />
                            </Button>
                        </Col>
                    </Row>

                    {/* LOADING */}
                    {this.state.loading &&
                    <div id="loader_overlay" className="loader_overlay"><Translate id="loading" /></div>
                    }

                    {/* NO RESULTS */}
                    {noResultsCondition &&
                    <center><Image src={emptyList} className="mt-5 mb-4" fluid /><br /><span className="st-text-empty-list"><Translate id="label_no_results" /></span></center>
                    }

                    {/* RESULTS */}
                    {resultsCondition &&
                        this.displayElements(this.state.soste)
                    }
                </Container>

                <STPopup show={this.state.showError} title={this.state.errorTitle} body={this.state.errorDescription} handleClosure={this.handleErrorPopupClose} />
            </div>
        )
    }
}

RiepilogoSoste.propTypes = {
    token: PropTypes.string,
    logout: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        token: state.session.token,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withGracefulUnmount(withLocalize(RiepilogoSoste)));
