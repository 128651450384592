export const local = {
	cognito_uri: 'https://dev-smarticket-admin-portal-opentech.auth.eu-west-1.amazoncognito.com',
	client_id: '5gegmd7qnke4q6hrr43s0jts1u',
	redirect_uri: 'http://localhost:3000/',
	// redirect_uri: 'http://localhost:9080/SmarticketAdminPortal/',
	scope: 'openid',
	logout_uri: 'http://localhost:3000/logout',
	// logout_uri: 'http://localhost:9080/SmarticketAdminPortal/logout',
	// stfe_rest_uri: 'http://localhost:9080/STFE',
	stfe_rest_uri: 'http://devweb2:8080/STFE',
	// stfe_soap_wsdl_uri: 'http://localhost:9080/STFE/GTT/ControlloSosteOperatore?wsdl',
	stfe_soap_wsdl_uri: 'http://devweb2:8080/STFE/GTT/ControlloSosteOperatore?wsdl',
	root_dir: ''
	// root_dir: '/SmarticketAdminPortal'
}
