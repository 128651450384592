export const dev = {
	cognito_uri: 'https://dev-smarticket-admin-portal-opentech.auth.eu-west-1.amazoncognito.com',
	client_id: '5gegmd7qnke4q6hrr43s0jts1u',
	redirect_uri: 'https://util.opentech.com/SmarticketAdminPortal/',
	scope: 'openid',
	logout_uri: 'https://util.opentech.com/SmarticketAdminPortal/logout',
	stfe_rest_uri: 'https://util.opentech.com/STFE',
	stfe_soap_wsdl_uri: 'https://util.opentech.com/STFE/GTT/ControlloSosteOperatore?wsdl',
	root_dir: '/SmarticketAdminPortal'
}
